import {
  AutomationTestJobStatus,
  DeploymentRequestDto,
  DeploymentStatus,
} from "@/api/models";
import { formatRelative, intervalToDuration } from "date-fns";
export function formatBranchName(branchName: string): string {
  return branchName.replace("refs/heads/", "");
}

export function formatDate(date: string): string {
  return formatRelative(new Date(date), new Date());
}
export function getAge(deploymentRequest: DeploymentRequestDto): string {
  return getDuration(new Date(deploymentRequest.deployedDate), new Date());
}

export function getAgeInDays(deploymentRequest: DeploymentRequestDto): number {
  const deployedDate = new Date(deploymentRequest.deployedDate);
  const now = new Date();
  const differenceInMs = now.getTime() - deployedDate.getTime();
  const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
  return differenceInDays;
}

export function getDuration(start: Date, end: Date): string {
  const result = intervalToDuration({
    start: start,
    end: end,
  });
  let age = "";
  if (result.years > 0) {
    age += `${result.years}yrs`;
  }

  if (result.months > 0) {
    age += ` ${result.months}mos`;
  }

  if (result.days > 0) {
    age += ` ${result.days}d`;
  }

  if (result.hours > 0) {
    age += ` ${result.hours}h`;
  }

  if (result.minutes > 0) {
    age += ` ${result.minutes}m`;
  }

  if (result.seconds > 0) {
    age += ` ${result.seconds}s`;
  }

  return age;
}

export function formatStatus(num: number, enumType: any): string {
  return enumType[num].split(/(?=[A-Z])/).join(" ");
}

export function formatDeploymentStatus(status: DeploymentStatus): string {
  return formatStatus(status, DeploymentStatus);
}
export function formatAutomationTestJobStatus(
  status: AutomationTestJobStatus
): string {
  return formatStatus(status, AutomationTestJobStatus);
}
