<script setup lang="ts">
import { DeploymentRequestDto, DeploymentStatus } from "@/api/models";
import { getAgeInDays } from "@/common/utils";
import { computed } from "vue";

const props = defineProps({
  deploymentRequest: {
    type: Object,
    required: true,
  },
});
const maxAge = 45;
const deploymentRequest = props.deploymentRequest as DeploymentRequestDto;

const ageInDays = computed(() => {
  return getAgeInDays(deploymentRequest);
});

const progress = computed(() => {
  return Math.min(100, (ageInDays.value / maxAge) * 100);
});
const reachedMaxAge = computed(() => {
  return progress.value >= 100;
});
</script>

<template>
  <div
    class="flex items-center"
    :title="`Deployment age - ${ageInDays} of ${maxAge} days${
      reachedMaxAge ? '. Consider removing this deployment' : ''
    }`"
  >
    <div v-if="reachedMaxAge">
      <img
        src="/src/assets/warning.svg"
        class="w-4 h-4 mr-2 inline-block"
        alt="Warning Icon"
        :title="`Older than ${maxAge} days. Consider removing this deployment`"
      />
    </div>
    <div
      class="relative w-full border rounded-lg bg-gradient-to-r from-green-500 via-yellow-500 via-orange-500 to-red-500 h-2 p-0 overflow-hidden flex justify-end"
      :class="reachedMaxAge ? 'max-w-[160px]' : 'max-w-[192px]'"
    >
      <div class="bg-white h-4" :style="{ width: `${100 - progress}%` }"></div>
    </div>
  </div>
</template>

<style></style>
