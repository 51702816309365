<script setup lang="ts">
import {
  AutomationTestJobDto,
  AutomationTestJobStatus,
  BuildInfoDto,
  DeploymentRequestDto,
} from "@/api/models";
import DeploymentStatusComponent from "@/components/DeploymentStatusComponent.vue";
import AutomationTestJobStatusComponent from "@/components/AutomationTestJobStatusComponent.vue";
import DeploymentButtonsComponent from "@/components/DeploymentButtonsComponent.vue";
import {
  formatBranchName,
  getAge,
  formatDate,
  getDuration,
} from "@/common/utils";
import tickSvg from "@/assets/tick.svg";
import warningSvg from "@/assets/warning.svg";
import inprogressSvg from "@/assets/inprogress.svg";
import { computed, ref, toRefs, defineProps, watch, watchEffect } from "vue";
import AgeIndicatorComponent from "@/components/AgeIndicatorComponent.vue";

const props = defineProps<{
  deploymentRequest: DeploymentRequestDto;
  automationTestJobs: AutomationTestJobDto[];
  isBusy: boolean;
}>();
const { deploymentRequest, isBusy, automationTestJobs } = toRefs(props);

const emits = defineEmits([
  "run-build",
  "destroy",
  "run-automation-tests",
  "restart-web-app",
  "reset-database",
  "open-logs-modal",
]);

const showAllAutomationJobs = ref<boolean>(false);

const automationTestJobsSorted = computed(() => {
  const jobs = automationTestJobs.value;
  return jobs
    .filter((d) => {
      return (
        d.parentDeploymentRequestId ==
        deploymentRequest.value.deploymentRequestId
      );
    })
    .sort((a, b) => {
      if (a.status == b.status) {
        return 0;
      }
      if (a.status < b.status) {
        return -1;
      }
      return 1;
    })
    .slice(
      0,
      showAllAutomationJobs.value ? automationTestJobs.value.length : 1
    );
});

const hasAutomationJobs = computed(() => {
  return (
    automationTestJobsSorted != null &&
    automationTestJobsSorted.value.length > 0
  );
});
const currentBuildInfo = computed(() => {
  const buildInfo = props.deploymentRequest.buildInfo;
  if (buildInfo?.status == "inProgress") {
    return {
      tooltip: "New build available soon",
      icon: inprogressSvg,
    };
  }
  if (isLatestDeployed.value || !buildInfo || buildInfo.result != "succeeded") {
    return {
      tooltip: "Latest build deployed",
      icon: tickSvg,
    };
  }

  return {
    tooltip: "New build available to deploy",
    icon: warningSvg,
  };
});
const currentBuildId = computed(() => {
  if (!props.deploymentRequest?.currentBuild) {
    return null;
  }
  const currentBuild = props.deploymentRequest.currentBuild;
  const buildId = currentBuild.substring(currentBuild.lastIndexOf(".") + 1);
  if (!buildId) {
    return 0;
  }
  return Number(buildId);
});
const isLatestDeployed = computed(() => {
  return props.deploymentRequest.buildInfo?.buildId == currentBuildId.value;
});

const currentBuildUrl = computed(() => {
  if (!props.deploymentRequest?.currentBuild) {
    return null;
  }
  const buildId = currentBuildId.value;
  if (!buildId) {
    return null;
  }
  return `https://dev.azure.com/jigsawcreate/Zadar/_build/results?buildId=${buildId}`;
});

const hasBuildInfo = computed(() => {
  return !!props.deploymentRequest.buildInfo;
});

function getBuildRuntime(buildInfo: BuildInfoDto): string {
  if (!buildInfo || !buildInfo.startTime) {
    return "";
  }
  const end = buildInfo.finishTime
    ? new Date(buildInfo.finishTime)
    : new Date();
  return getDuration(new Date(buildInfo.startTime), end);
}

function runBuild(deploymentRequest: DeploymentRequestDto): void {
  emits("run-build", deploymentRequest);
}
function restartWebApp(deploymentRequest: DeploymentRequestDto): void {
  emits("restart-web-app", deploymentRequest);
}
function runAutomationTests(deploymentRequest: DeploymentRequestDto): void {
  emits("run-automation-tests", deploymentRequest);
}
function resetDatabase(deploymentRequest: DeploymentRequestDto): void {
  emits("reset-database", deploymentRequest);
}
function destroyDeployment(deploymentRequest: DeploymentRequestDto): void {
  emits("destroy", deploymentRequest);
}
function openLogsModal(deploymentRequest: DeploymentRequestDto): void {
  emits("open-logs-modal", deploymentRequest);
}
</script>
<template>
  <tr v-if="!hasAutomationJobs">
    <td colspan="7" class="text-sm text-gray-700 text-center">
      <span class="items-center bg-yellow-200 rounded-b-lg px-5 pb-1">
        <img
          src="@/assets/warning.svg"
          class="w-4 h-4 mr-2 inline-block"
          alt="Warning Icon"
        />
        <span>
          Automation tests have not yet been run on this branch. Please ensure
          they are run before merging into release/master
        </span>
      </span>
    </td>
  </tr>

  <tr
    class="hover:bg-gray-100 mb-10"
    :title="`Deployment Request Id: ${deploymentRequest.deploymentRequestId}`"
  >
    <td
      class="px-5 py-5 bg-white text-sm"
      :class="{
        'border-b border-gray-300': !hasAutomationJobs,
      }"
    >
      <div class="flex">
        <DeploymentStatusComponent :deploymentRequest="deploymentRequest" />
        <div>
          <a
            class="text-blue-500"
            v-if="deploymentRequest.deploymentUrl"
            :href="deploymentRequest.deploymentUrl"
            target="_blank"
            :title="deploymentRequest.deploymentUrl"
            >{{ formatBranchName(deploymentRequest.branchName) }}</a
          >
          <span v-else>{{
            formatBranchName(deploymentRequest.branchName)
          }}</span>
        </div>
      </div>

      <div class="flex mt-2 mb-2"></div>
      <AgeIndicatorComponent :deployment-request="deploymentRequest" />
      <span v-if="deploymentRequest.deployedDate" class="text-xs text-gray-400">
        age {{ getAge(deploymentRequest) }}</span
      >
    </td>

    <td class="px-5 py-5 border-b border-gray-300 bg-white text-sm">
      {{ formatDate(deploymentRequest.requestedDate) }} by
      <strong>{{ deploymentRequest.requestedBy }}</strong>
    </td>

    <td class="px-5 py-5 border-b border-gray-300 bg-white text-sm">
      <span v-if="deploymentRequest.deployedDate">
        {{ formatDate(deploymentRequest.deployedDate) }}</span
      >
    </td>
    <td class="px-5 py-5 border-b border-gray-300 bg-white text-sm">
      <span v-if="deploymentRequest.destroyRequestedDate">
        {{ formatDate(deploymentRequest.destroyRequestedDate) }} by
        <strong>{{ deploymentRequest.destroyedBy }}</strong>
      </span>
    </td>

    <td class="px-5 py-5 border-b border-gray-300 bg-white text-sm">
      <span v-if="deploymentRequest.destroyedDate">
        {{ formatDate(deploymentRequest.destroyedDate) }}
      </span>
    </td>

    <td class="px-5 py-5 border-b border-gray-300 bg-white text-sm">
      <div>
        <!-- Current Build Information -->
        <div class="flex items-center align-middle" title="Current build">
          <span class="text-xs text-gray-400">Deployed</span>
          <a class="text-blue-500 ml-1" :href="currentBuildUrl" target="_blank">
            {{ currentBuildId }}
          </a>
          <img
            :src="currentBuildInfo.icon"
            class="w-4 ml-1"
            :title="currentBuildInfo.tooltip"
          />
        </div>
        <!-- Latest Build Information -->
        <div class="mt-1">
          <div v-if="hasBuildInfo">
            <span class="text-xs text-gray-400">Latest</span>
            <a
              v-if="hasBuildInfo"
              class="text-blue-500 ml-1"
              :href="deploymentRequest.buildInfo.buildUrl"
              target="_blank"
              :title="`Build ID: ${deploymentRequest.buildInfo.buildId}`"
            >
              {{ deploymentRequest.buildInfo.buildId }}
            </a>
          </div>
          <div v-else>
            <span class="text-xs text-gray-400"
              >Could not get latest build info</span
            >
          </div>

          <div v-if="hasBuildInfo">
            <span class="text-xs text-gray-400 text-nowrap">
              {{ getBuildRuntime(deploymentRequest.buildInfo) }}
            </span>
            <span
              class="ml-1"
              :class="{
                'text-green-500':
                  deploymentRequest.buildInfo.result === 'succeeded',
                'text-red-500': deploymentRequest.buildInfo.result === 'failed',
                'text-gray-500': !deploymentRequest.buildInfo.result,
              }"
            >
              {{
                deploymentRequest.buildInfo.result ??
                deploymentRequest.buildInfo.status
              }}
            </span>
          </div>
        </div>
      </div>
    </td>

    <td class="px-5 py-5 border-b border-gray-300 bg-white text-sm">
      <div class="flex flex-col items-end">
        <DeploymentButtonsComponent
          :deployment-request="deploymentRequest"
          :is-busy="isBusy"
          @run-build="runBuild"
          @restart-web-app="restartWebApp"
          @run-automation-tests="runAutomationTests"
          @reset-database="resetDatabase"
          @destroy="destroyDeployment"
        />
        <span
          class="text-xs text-blue-500 hover:cursor-pointer mt-4"
          @click="openLogsModal(deploymentRequest)"
          >view logs</span
        >
      </div>
    </td>
  </tr>

  <tr v-if="hasAutomationJobs">
    <td></td>
    <td
      class="px-5 py-5"
      @click="showAllAutomationJobs = !showAllAutomationJobs"
    >
      <span class="text-sm">Automation Jobs</span>
      <div class="text-xs text-blue-500 hover:cursor-pointer">
        <span v-if="showAllAutomationJobs">Show less</span>
        <span v-else>Show more</span>
      </div>
    </td>
  </tr>
  <template
    v-if="hasAutomationJobs"
    v-for="automationTestJob in automationTestJobsSorted"
    :key="automationTestJob.automationTestJobId"
  >
    <tr
      :title="`Automation Test Job Id: ${automationTestJob.automationTestJobId}`"
    >
      <td class="border-b border-gray-300"></td>
      <td class="px-5 py-5 border-b border-gray-300 bg-white text-sm">
        <AutomationTestJobStatusComponent
          :automation-test-job="automationTestJob"
          :show-text="true"
        /><a
          v-if="automationTestJob.buildUrl"
          :href="automationTestJob.buildUrl"
          target="_blank"
          class="text-xs text-blue-500 hover:cursor-pointer"
          @click="openLogsModal(deploymentRequest)"
          >view build</a
        >
        <p v-else class="text-xs text-gray-500 mt-2">
          No build yet, please wait.
        </p>
        <p class="text-xs mt-2">
          {{ formatDate(automationTestJob.requestedDate) }} by
          <strong>{{ automationTestJob.requestedBy }}</strong>
        </p>
      </td>
      <td
        colspan="5"
        class="px-5 py-5 border-b border-gray-300 bg-white text-sm"
      ></td>
    </tr>
  </template>
</template>
